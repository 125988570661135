// Import dependencies
import React from "react"
import {Row, Col, Spin, Button} from "antd"
import {Form} from "antd"

// Import components
import InputField from "../form/input/inputField"
import GeneralButton from "../form/buttons/GeneralButton"

// Import styles
import "../../pages/step.css"
import "../../pages/recycle.css"
import "./new-account-form.css"
import {ParagraphText} from "../../styled-components/UILibrary"
import {Constants} from "../../constants/Constants"
import Lock from "../../assets/icons/lock-white.svg";
import {navigate, Link} from "gatsby";

/*
    Form for creating new account for membership purchase
*/
class NewAccountForm extends React.Component {
    handleSubmit = e => {
        e.preventDefault()
        this.props.form.validateFields(err => {
            if (!err) {
                this.props.createNewAccount()
            }
        })
    }

    render() {
        const {form} = this.props

        return (
            <div
            className="account_form_container"
          >
            <div className="account_form">
                
              <Form
                onSubmit={this.handleSubmit}
            >
              <div className="account_form_title">
                <h1>Sign up</h1>
              </div>

                        <InputField
                            placeholder="First Name"
                            form={form}
                            id="first-name"
                            error="Please enter valid first name."
                            isRequired
                            type="string"
                            initialValue={this.props.account_info.first_name}
                            onChange={e => this.props.onChange("first_name", e)}
                        />
                        <InputField
                            placeholder="Last Name"
                            form={form}
                            id="last-name"
                            error="Please enter valid last name."
                            isRequired
                            type="string"
                            initialValue={this.props.account_info.last_name}
                            onChange={e => this.props.onChange("last_name", e)}
                        />
                        <InputField
                            placeholder="Email"
                            form={form}
                            id="email"
                            error="Please enter valid email."
                            isRequired
                            type="email"
                            initialValue={this.props.account_info.email}
                            onChange={e => this.props.onChange("email", e)}
                        />
                        <span class="password_input_container"><InputField
                            password
                            placeholder="Password"
                            form={form}
                            id="Password"
                            error=""
                            isRequired
                            type="string"
                            initialValue={this.props.account_info.password}
                            onChange={e => this.props.onChange("password", e)}
                        /></span>
                        {this.props.errorMessage && !this.props.isLoading && (
                            <p className={"account_error_text"}>
                                {this.props.errorMessage}
                            </p>
                        )}                        
                      {
                        this.props.notLoadingPhase
                        ? <div onClick={(e) => this.handleSubmit(e)} className="account_form_btn">Create my {this.props.isPaid ? this.props.planIsSME ? "Hero SME" : "Hero" : "free"} account</div>
                        : <div className="account_form_btn account_form_btn_disabled">Loading</div>
                      }
              <span className="account_form_subtext">By creating an account, you agree to Reinin’s<br/><a href="https://help.reinin.sg/topics/our-features/terms-of-service/">Terms of Service</a> and <a href="https://help.reinin.sg/topics/our-features/data-protection-notice-pdpa/">Privacy Policy</a></span>
              {/*this.state.isLoading ? (
                <div className="login__loader">
                  <Spin size="large" />
                </div>
              ) : null */}
            </Form>
            </div>
            <div className="account_form_divider_container">
              <div className="account_form_divider"></div>
              <span>OR</span>
              <div className="account_form_divider"></div>
            </div>
            <div className="account_social_btn_container">
              <div className="account_form_title">
                <h1>Sign-up with social media</h1>
              </div>
              <div className="account_social_btn account_social_btn_facebook" onClick={() => this.props.registerWithFacebook()}>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.8389 19.3359L21.5039 15H17.3437V12.1862C17.3437 11 17.9249 9.84375 19.7882 9.84375H21.6797V6.15234C21.6797 6.15234 19.9631 5.85938 18.322 5.85938C14.8956 5.85938 12.6562 7.93594 12.6562 11.6953V15H8.84766V19.3359H12.6562V29.8178C13.4199 29.9376 14.2027 30 15 30C15.7973 30 16.5801 29.9376 17.3437 29.8178V19.3359H20.8389Z" fill="white"/>
                </svg>
                <span>Continue with Facebook</span>
              </div>
              <div className="account_social_btn account_social_btn_google" onClick={() => this.props.registerWithGoogle()}>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M30 15.3323C30 14.0989 29.8979 13.1989 29.6769 12.2656H15.3062V17.8322H23.7415C23.5715 19.2156 22.6531 21.2989 20.6122 22.6989L20.5836 22.8852L25.1274 26.3348L25.4422 26.3656C28.3333 23.7489 30 19.8989 30 15.3323Z" fill="#4C8BF5"/>
                  <path d="M15.3053 30.0001C19.4379 30.0001 22.9073 28.6667 25.4414 26.3668L20.6114 22.7C19.3189 23.5834 17.5842 24.2 15.3053 24.2C11.2578 24.2 7.8224 21.5835 6.5978 17.9668L6.4183 17.9817L1.69362 21.5651L1.63184 21.7334C4.14882 26.6334 9.3189 30.0001 15.3053 30.0001Z" fill="#0AAE67"/>
                  <path d="M6.59857 17.9664C6.27545 17.0331 6.08845 16.033 6.08845 14.9997C6.08845 13.9663 6.27545 12.9664 6.58157 12.033L6.57301 11.8343L1.78913 8.19336L1.63261 8.26632C0.595245 10.2997 0 12.5831 0 14.9997C0 17.4164 0.595245 19.6996 1.63261 21.733L6.59857 17.9664Z" fill="#FBBC05"/>
                  <path d="M15.3053 5.79996C18.1794 5.79996 20.1182 7.01662 21.2237 8.03336L25.5434 3.9C22.8904 1.48334 19.4379 0 15.3053 0C9.3189 0 4.14882 3.36664 1.63184 8.2666L6.5808 12.0333C7.8224 8.41666 11.2578 5.79996 15.3053 5.79996Z" fill="#EE0202"/>
                </svg>
                <span>Continue with Google</span>
              </div>
              <div className="account_social_btn account_social_btn_apple">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M23.4003 15.9388C23.3663 12.1423 26.5011 10.3197 26.6429 10.2308C24.8789 7.65005 22.1336 7.2965 21.1542 7.2549C18.8192 7.01856 16.5958 8.62943 15.4122 8.62943C14.2286 8.62943 12.3984 7.28894 10.4624 7.32675C7.91373 7.36456 5.56739 8.80716 4.25525 11.0873C1.61018 15.676 3.58028 22.4768 6.15539 26.2015C7.41648 28.0222 8.91768 30.0736 10.8916 29.998C12.7936 29.9223 13.5121 28.769 15.8073 28.769C18.1026 28.769 18.7493 29.998 20.7591 29.9621C22.8029 29.9223 24.0999 28.1016 25.3515 26.2752C26.796 24.1614 27.3935 22.1157 27.4275 22.0079C27.3821 21.989 23.4419 20.4784 23.4003 15.9388Z" fill="white"/>
                  <path d="M19.6245 4.79096C20.6719 3.52231 21.3791 1.75827 21.1843 0C19.6754 0.0605675 17.8491 1.00587 16.7657 2.27452C15.7958 3.39575 14.945 5.19185 15.1756 6.91432C16.8584 7.04478 18.5769 6.05775 19.6245 4.79096Z" fill="white"/>
                </svg>
                <span>Continue with Apple</span>
              </div>
            </div>
            <span className="account_bottom_link">Already have an account?<Link to="/login">Login</Link></span>
          </div>
        )
    }
}

export default Form.create({name: "new-account"})(NewAccountForm)
