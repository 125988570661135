// Import dependencies
import React from "react";
import { Form } from "antd";

// Import components
import Layout from "../layouts/layout";
import SEO from "../components/seo/seo";
import {PageContainer, TitleText} from "../styled-components/UILibrary";
import TitleSection from "../components/sections/titleSection";
import NewAccountForm from "../components/new-account/newAccountForm";
import SectionSeparator from "../components/separators/sectionSeparator";
import Logo from "../assets/icons/logo_green_login.svg";
import { Link, navigate } from "gatsby"

// Import styles
import "./step.css";
import "./recycle.css";

// Ger firebase API
import { getFirebase } from "../api/firebase";

// Import from Redux
import { connect } from "react-redux";
import { logUserIn } from "../redux/actions/userAction";

// Import utils
import {
  generatePassComplexityError,
  PASSWORD_REGEX,
} from "../components/account/utils/registerUtils";
import { DEPLOY_TYPE, STRIPE_CONSTANT } from "../constants/stripe";

import HeaderSeparator from "../components/separators/headerSeparator";

import { loadStripe } from "@stripe/stripe-js"

/*
    New account page for a membership purchase
*/
class NewAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account_info: {
        email: null,
        first_name: null,
        last_name: null,
        password: null,
        confirmPassword: null,
        isLoading: false,
      },
      errorMessage: null,
      uniqueRefealCode: true,
      notLoadingPhase: true
    };
    this.createNewAccount = this.createNewAccount.bind(this);
    this.registerWithGoogle = this.registerWithGoogle.bind(this);
    this.registerWithFacebook = this.registerWithFacebook.bind(this);
    this.isBrowser = this.isBrowser.bind(this);
  }

  
  
  isBrowser = () => typeof window !== "undefined";


  componentWillMount() {
    if (this.props.loggedIn && this.isBrowser()) {
      navigate("/account");
    }
  }

  // Gets called on change of the form input fields
  onChange = (field, e) => {
    let temp = { ...this.state.account_info };
    temp[field] = e.target.value;
    this.setState({ account_info: temp });
  };

  createNewAccount = () => {
    
    this.setState({notLoadingPhase: false})
    const { location } = this.props;

      // If password is valid
      if (this.state.account_info.password.match(PASSWORD_REGEX)) {
        this.setState({ isLoading: true });

        // Create a new user
        const app = import("firebase/app");
        const auth = import("firebase/auth");
        const firestore = import("firebase/firestore");
        const fuctions = import("firebase/functions")

        Promise.all([app, auth, firestore, fuctions]).then(async ([firebase]) => {
          var stripeCheckoutMember = getFirebase(firebase)
          .functions()
          .httpsCallable("stripeCheckoutMember");

          getFirebase(firebase)
            .auth()
            .createUserWithEmailAndPassword(
              this.state.account_info.email,
              this.state.account_info.password
            )
            .then(() => {

              let uniqueRefealCode = true;

              let today = new Date();
              const dd = today.getDate();
              const mm = today.getMonth();
              const arrayMonths = [ "January", "February", "March", "April", "May", "June",
               "July", "August", "September", "October", "November", "December"];
              const yyyy = today.getFullYear();
              today =  dd + ' ' + arrayMonths[mm] + ' ' + yyyy;

              let user = firebase.auth().currentUser;
              const membership_code = {
                SME: {
                    yearly: "YS" + Math.floor(100000 + Math.random() * 900000),
                    quarterly: "QS" + Math.floor(100000 + Math.random() * 900000)
                },
                Regular: {
                    yearly: "YR" + Math.floor(100000 + Math.random() * 900000),
                    quarterly: "QR" + Math.floor(100000 + Math.random() * 900000)
                }
              }
              // Yearly SME
              let membershipCodeYS = membership_code["SME"]["yearly"];
              // Yearly Regular
              let membershipCodeYR = membership_code["Regular"]["yearly"];
              // Quaretly SME
              let membershipCodeQS = membership_code["SME"]["quarterly"];
              // Quaretly Regular
              let membershipCodeQR = membership_code["Regular"]["quarterly"];
              if (user) {
                // Create user profile object
                const userProfile = {
                  account_info: {
                    title: null,
                    first_name: this.state.account_info.first_name,
                    last_name: this.state.account_info.last_name,
                    email: user.email,
                    phone: null,
                    sign_date: today
                  },
                  billing_info: {
                    apt_suite_etc: null,
                    city: null,
                    country: null,
                    home_address: null,
                    postal_code: null,
                  },
                  contact_info: {
                    apt_suite_etc: null,
                    city: null,
                    country: null,
                    home_address: null,
                    postal_code: null,
                  },
                  membership_info: {
                    is_member: false,
                    start_date: null,
                    end_date: null,
                    stripe_customer_id: null,
                    stripe_subscription_id: null,
                    membership_id: null,
                    membership_interval: null,
                    membership_code_ys: membershipCodeYS,
                    membership_code_yr: membershipCodeYR,
                    membership_code_qs: membershipCodeQS,
                    membership_code_qr: membershipCodeQR
                  },
                  premium_orders: [],
                  general_orders: [],
                  transfer_info: {
                    UserCreditPreference: "Pay Now",
                    creditDetails: { "Phone number": "" }
                  },
                  brandFollow: [],
                  cash: 0,
                  coin: 0
                };

                let refeal_codesArray = [];
                let refeal_codesEmailArray = [];

                getFirebase(firebase)
                  .firestore().collection("refeal_codes").get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                      refeal_codesArray.push(doc.data().code);
                      refeal_codesEmailArray.push(doc.data().email);
                    });
                });


                if (!refeal_codesEmailArray.includes(user.email)) {
                    let refeal_code = "reinin-" + this.state.account_info.first_name.replace(/ /g, "").toLowerCase().substring(0, 4);

                      while (uniqueRefealCode) {
                        refeal_code += Math.floor(Math.random() * 10000);
                        refeal_code += String.fromCharCode(97+Math.floor(Math.random() * 26));
                        refeal_code += String.fromCharCode(97+Math.floor(Math.random() * 26));

                        if (!refeal_codesArray.includes(refeal_code)) {
                          uniqueRefealCode = false;
                        }

                      }


                    if (!uniqueRefealCode) {
                      getFirebase(firebase)
                        .firestore().collection("refeal_codes").add({
                          code: refeal_code,
                          email: user.email
                      });
                    }

                  }

                // Create Firestore user record
                firebase
                  .firestore()
                  .collection("users")
                  .doc(user.uid)
                  .set(userProfile)
                  .then(() => {
                    this.props.dispatch(logUserIn(user));
                    this.setState({ isLoading: false });
                    console.log("Create Firestore user record complete");
                    // Re-direct to payment
                    if (location.state && location.state.isPaid) {
                      let planIsYearly = location.state.planIsYearly ? "yearly" : "quarterly";
                      let planIsSME = location.state.planIsSME ? "SME" : "Regular";
                      console.log({
                        priceId: STRIPE_CONSTANT[DEPLOY_TYPE]["MEMBER_PLAN"][planIsSME][planIsYearly],
                        websiteUrl: "https://quirky-poitras-f877b7.netlify.app",
                        successParams: "?key=" + membership_code[planIsSME][planIsYearly] + "&usertoken=" + user.uid
                      });
                      stripeCheckoutMember({
                        priceId: STRIPE_CONSTANT[DEPLOY_TYPE]["MEMBER_PLAN"][planIsSME][planIsYearly],
                        websiteUrl: "https://quirky-poitras-f877b7.netlify.app",
                        successParams: "?key=" + membership_code[planIsSME][planIsYearly] + "&usertoken=" + user.uid,
                        isTest: false
                      })
                      .then((data) => {
                        if (this.isBrowser()) {
                          window.location.replace(data.data.url);
                        }          
                      });   
                    } else {
                      navigate("/account");
                    }
                  })
                  .catch(() =>
                    this.setState({
                      error:
                        "There was an issue creatig a new user. Please try again.",
                      isLoading: false,
                    })
                  );
              }
            })
            .catch(error => {
              // Handle Errors here.
              this.setState({
                errorMessage: error.message,
                isLoading: false,
                notLoadingPhase: true
              });
            });
        });
      } else {
        this.setState({
          errorMessage: generatePassComplexityError(),
          notLoadingPhase: true
        });
      }
  };
  registerWithGoogle = () => {
    // Reset errors
    this.setState({ error: null, isLoading: true })

    const app = import("firebase/app")
    const auth = import("firebase/auth")
    const firestore = import("firebase/firestore")

    Promise.all([app, auth, firestore]).then(([firebase]) => {
      getFirebase(firebase)
        .auth()
        .signInWithPopup(new firebase.auth.GoogleAuthProvider())
        .then(async (result) => {


          getFirebase(firebase)
          .firestore().collection("users").doc(result.user.uid)
          .get().then((doc) => {
              if (doc.exists) {
                this.props.dispatch(logUserIn(result.user));
                this.setState({ isLoading: false });
                  navigate("/account");
              } else {
                let uniqueRefealCode = true;
      
                let today = new Date();
                const dd = today.getDate();
                const mm = today.getMonth();
                const arrayMonths = [ "January", "February", "March", "April", "May", "June",
                 "July", "August", "September", "October", "November", "December"];
                const yyyy = today.getFullYear();
                today =  dd + ' ' + arrayMonths[mm] + ' ' + yyyy;

                const membership_code = {
                  SME: {
                      yearly: "YS" + Math.floor(100000 + Math.random() * 900000),
                      quarterly: "QS" + Math.floor(100000 + Math.random() * 900000)
                  },
                  Regular: {
                      yearly: "YR" + Math.floor(100000 + Math.random() * 900000),
                      quarterly: "QR" + Math.floor(100000 + Math.random() * 900000)
                  }
                }
                // Yearly SME
                let membershipCodeYS = membership_code["SME"]["yearly"];
                // Yearly Regular
                let membershipCodeYR = membership_code["Regular"]["yearly"];
                // Quaretly SME
                let membershipCodeQS = membership_code["SME"]["quarterly"];
                // Quaretly Regular
                let membershipCodeQR = membership_code["Regular"]["quarterly"];
  

                // Create user profile object
                const userProfile = {
                  account_info: {
                    title: null,
                    first_name: result.user.displayName.split(" ")[0],
                    last_name: result.user.displayName.split(" ")[1],
                    email: result.user.email,
                    phone: null,
                    sign_date: today
                  },
                  billing_info: {
                    apt_suite_etc: null,
                    city: null,
                    country: null,
                    home_address: null,
                    postal_code: null,
                  },
                  contact_info: {
                    apt_suite_etc: null,
                    city: null,
                    country: null,
                    home_address: null,
                    postal_code: null,
                  },
                  membership_info: {
                    is_member: false,
                    start_date: null,
                    end_date: null,
                    stripe_customer_id: null,
                    stripe_subscription_id: null,
                    membership_id: null,
                    membership_interval: null,
                    membership_code_ys: membershipCodeYS,
                    membership_code_yr: membershipCodeYR,
                    membership_code_qs: membershipCodeQS,
                    membership_code_qr: membershipCodeQR
                  },
                  premium_orders: [],
                  general_orders: [],
                  transfer_info: {
                    UserCreditPreference: "Pay Now",
                    creditDetails: { "Phone number": "" }
                  },
                  brandFollow: [],
                  cash: 0,
                  coin: 0
                }
      
                let refeal_codesArray = [];
                let refeal_codesEmailArray = [];
      
                getFirebase(firebase)
                  .firestore().collection("refeal_codes").get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                      refeal_codesArray.push(doc.data().code);
                      refeal_codesEmailArray.push(doc.data().email);
                    });
                });
      
      
                if (!refeal_codesEmailArray.includes(result.user.email)) {
                    let refeal_code = "reinin-" + result.user.displayName.split(" ")[0].replace(/ /g, "").toLowerCase().substring(0, 4);
      
                      while (uniqueRefealCode) {
                        refeal_code += Math.floor(Math.random() * 10000);
                        refeal_code += String.fromCharCode(97+Math.floor(Math.random() * 26));
                        refeal_code += String.fromCharCode(97+Math.floor(Math.random() * 26));
      
                        if (!refeal_codesArray.includes(refeal_code)) {
                          uniqueRefealCode = false;
                        }
      
                      }
      
      
                    if (!uniqueRefealCode) {
                      getFirebase(firebase)
                        .firestore().collection("refeal_codes").add({
                          code: refeal_code,
                          email: result.user.email
                      });
                    }
      
                  }
      
                // Create Firestore user record
                firebase
                  .firestore()
                  .collection("users")
                  .doc(result.user.uid)
                  .set(userProfile)
                  .then(() => {
                    this.props.dispatch(logUserIn(result.user))
                    this.setState({ isLoading: false })
                    navigate("/account");
                  })
                  .catch(() =>
                    this.setState({
                      error:
                        "There was an issue creatig a new user. Please try again.",
                      isLoading: false,
                    })
                  )
              }
          }).catch((error) => {
              console.log("Error getting document:", error);
          });
        })
        .catch(error => {
          console.log(error);
          this.setState({ error: error.message, isLoading: false })
        })
    })
  }
  registerWithFacebook = () => {
    // Reset errors
    this.setState({ error: null, isLoading: true })

    const app = import("firebase/app")
    const auth = import("firebase/auth")
    const firestore = import("firebase/firestore")

    Promise.all([app, auth, firestore]).then(([firebase]) => {
      getFirebase(firebase)
        .auth()
        .signInWithPopup(new firebase.auth.FacebookAuthProvider())
        .then(async (result) => {
          // The signed-in user info.
          var user = result.user

          getFirebase(firebase)
          .firestore().collection("users").doc(result.user.uid)
          .get().then((doc) => {
                if (doc.exists) {
                  this.props.dispatch(logUserIn(result.user));
                  this.setState({ isLoading: false });
                    navigate("/account");
                } else {
                let uniqueRefealCode = true;
      
                let today = new Date();
                const dd = today.getDate();
                const mm = today.getMonth();
                const arrayMonths = [ "January", "February", "March", "April", "May", "June",
                 "July", "August", "September", "October", "November", "December"];
                const yyyy = today.getFullYear();
                today =  dd + ' ' + arrayMonths[mm] + ' ' + yyyy;
      
                const membership_code = {
                  SME: {
                      yearly: "YS" + Math.floor(100000 + Math.random() * 900000),
                      quarterly: "QS" + Math.floor(100000 + Math.random() * 900000)
                  },
                  Regular: {
                      yearly: "YR" + Math.floor(100000 + Math.random() * 900000),
                      quarterly: "QR" + Math.floor(100000 + Math.random() * 900000)
                  }
                }
                // Yearly SME
                let membershipCodeYS = membership_code["SME"]["yearly"];
                // Yearly Regular
                let membershipCodeYR = membership_code["Regular"]["yearly"];
                // Quaretly SME
                let membershipCodeQS = membership_code["SME"]["quarterly"];
                // Quaretly Regular
                let membershipCodeQR = membership_code["Regular"]["quarterly"];  

                // Create user profile object
                const userProfile = {
                  account_info: {
                    title: null,
                    first_name: user.displayName.split(" ")[0],
                    last_name: user.displayName.split(" ")[1],
                    email: user.email,
                    phone: user.phoneNumber,
                    sign_date: today
                  },
                  billing_info: {
                    apt_suite_etc: null,
                    city: null,
                    country: null,
                    home_address: null,
                    postal_code: null,
                  },
                  contact_info: {
                    apt_suite_etc: null,
                    city: null,
                    country: null,
                    home_address: null,
                    postal_code: null,
                  },
                  membership_info: {
                    is_member: false,
                    start_date: null,
                    end_date: null,
                    stripe_customer_id: null,
                    stripe_subscription_id: null,
                    membership_id: null,
                    membership_interval: null,
                    membership_code_ys: membershipCodeYS,
                    membership_code_yr: membershipCodeYR,
                    membership_code_qs: membershipCodeQS,
                    membership_code_qr: membershipCodeQR
                  },
                  premium_orders: [],
                  general_orders: [],
                  transfer_info: {
                    UserCreditPreference: "Pay Now",
                    creditDetails: { "Phone number": "" }
                  },
                  brandFollow: [],
                  cash: 0,
                  coin: 0
                }
      
                let refeal_codesArray = [];
                let refeal_codesEmailArray = [];
      
                getFirebase(firebase)
                  .firestore().collection("refeal_codes").get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                      refeal_codesArray.push(doc.data().code);
                      refeal_codesEmailArray.push(doc.data().email);
                    });
                });
      
      
                if (!refeal_codesEmailArray.includes(user.email)) {
                    let refeal_code = "reinin-" + result.user.displayName.split(" ")[0].replace(/ /g, "").toLowerCase().substring(0, 4);
      
                      while (uniqueRefealCode) {
                        refeal_code += Math.floor(Math.random() * 10000);
                        refeal_code += String.fromCharCode(97+Math.floor(Math.random() * 26));
                        refeal_code += String.fromCharCode(97+Math.floor(Math.random() * 26));
      
                        if (!refeal_codesArray.includes(refeal_code)) {
                          uniqueRefealCode = false;
                        }
      
                      }
      
      
                    if (!uniqueRefealCode) {
                      getFirebase(firebase)
                        .firestore().collection("refeal_codes").add({
                          code: refeal_code,
                          email: user.email
                      });
                    }
      
                  }
      
                // Create Firestore user record
                firebase
                  .firestore()
                  .collection("users")
                  .doc(result.user.uid)
                  .set(userProfile)
                  .then(() => {
                    this.props.dispatch(logUserIn(result.user))
                    this.setState({ isLoading: false })
                    navigate("/account");
                  })
                  .catch(() =>
                    this.setState({
                      error:
                        "There was an issue creatig a new user. Please try again.",
                      isLoading: false,
                    })
                  );
              }
          }).catch((error) => {
              console.log("Error getting document:", error);
          });

          })
        .catch(error => {
          this.setState({ error: error.message, isLoading: false })
        })
    })
  }

  render() {
    const { location } = this.props;

    return (

      <Layout noWhatsapp noHeader registerFooter>
      <SEO title="New Account"/>
      <div className="account_container">
        <Link to="/" className="account_logo" >
          <Logo />
        </Link>
        <NewAccountForm
              account_info={this.state.account_info}
              createNewAccount={this.createNewAccount}
              registerWithGoogle={this.registerWithGoogle}
              registerWithFacebook={this.registerWithFacebook}
              onChange={this.onChange}
              errorMessage={this.state.errorMessage}
              isLoading={this.state.isLoading}
              isPaid={location.state && location.state.isPaid}
              planIsSME={location.state && location.state.planIsSME}
              notLoadingPhase={this.state.notLoadingPhase}
            />
      </div>
    </Layout>

    );
  }
}

// Connect redux to component
const mapStateToProps = state => ({
  loggedIn: state.user.isLoggedIn
})
export default connect(mapStateToProps)(NewAccount)